// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/UsedVehicles/PortalUsedVehiclesCard';
import store, {
  setApiVersion,
  setList,
  setMethod,
  setPerPage,
  setSort,
} from '../../../stores/ListPagination';
import { configureList } from '../../ListPagination';

export default class PortalUsedVehiclesCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedModels: [],
      isFetching: true,
    };

    setSort(this.props.sortParams);
    setPerPage(this.props.maxItems);

    setApiVersion('v2');
    setMethod('getUsedModels');

    setList().then(() => {
      configureList();
    });

    this.storeWatch();
  }

  componentDidMount() {
    this.initCarousel();
  }

  storeWatch() {
    store().watch(currentState => {
      this.setState({
        usedModels: currentState.items,
        isFetching: currentState.isFetching,
      });
    });
  }

  initCarousel() {
    const carousel = new window.Carousel(this.elCarousel, {
      slidesPerView: 1.1,
      slidesOffsetBefore: 0,
      centerInsufficientSlides: true,
      navigation: {
        nextEl: '.vehicles-new__carousel-next',
        prevEl: '.vehicles-new__carousel-prev',
      },
      scrollbar: {
        el: '.carousel-used-models2 .carousel__scrollbar',
        draggable: true,
      },
      observer: true,
      observeParents: true,
      breakpoints: {
        576: {
          slidesPerView: 2.1,
        },
        768: {
          slidesPerView: 3.1,
        },
        992: {
          slidesPerView: 4.1,
        },
        1200: {
          slidesPerView: 5.2,
        },
      },
      on: {
        observerUpdate: () => {
          window.matchHeight.match('.used-models-carousel');
        },
      },
    });

    return carousel;
  }

  render() {
    const { usedModels, isFetching } = this.state;
    const { showUnitTag, cardLink } = this.props;

    return (
      <div
        ref={node => {
          this.elCarousel = node;
        }}
        className="carousel used-models-carousel swiper-container"
      >
        <ul className="swiper-wrapper">
          {isFetching &&
            [1, 2, 3, 4, 5].map(item => (
              <li className="carousel__item swiper-slide" key={item}>
                <div className="ph-item mr-3" style={{ width: '100%' }}>
                  <div className="ph-col-12">
                    <div className="ph-picture" />
                    <div className="ph-row">
                      <div className="ph-col-2 empty" />
                      <div className="ph-col-8 big" />
                      <div className="ph-col-2 empty" />
                    </div>
                    <div className="ph-row">
                      <div className="ph-col-8" />
                      <div className="ph-col-8" />
                      <div className="ph-col-8" />
                    </div>
                    <div className="ph-row">
                      <div className="ph-col-2 empty" />
                      <div className="ph-col-8 big" />
                      <div className="ph-col-2 empty" />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          {!isFetching &&
            usedModels.map(usedModel => (
              <li className="carousel__item swiper-slide" key={usedModel.slug}>
                <Card
                  moduleLink={cardLink}
                  slug={usedModel.slug}
                  itemImage={usedModel.item_image}
                  kind={usedModel.kind}
                  name={usedModel.name}
                  exchange={usedModel.exchange}
                  km={usedModel.km}
                  fuelText={usedModel.fuel_text}
                  itemUnit={usedModel.item_unit}
                  showUnitTag={showUnitTag}
                  fabricationYear={usedModel.fabrication_year}
                  modelYear={usedModel.model_year}
                  shielded={usedModel.shielded}
                  oldPrice={usedModel.old_price}
                  price={usedModel.price_value || usedModel.price}
                  subscriptionPrice={usedModel.subscription_price}
                />
              </li>
            ))}
        </ul>
        <div className="carousel__prev vehicles-new__carousel-prev swiper-button-prev">
          <i className="icon icon-arrow-r" />
        </div>
        <div className="carousel__next vehicles-new__carousel-next swiper-button-next">
          <i className="icon icon-arrow-r" />
        </div>
      </div>
    );
  }
}

PortalUsedVehiclesCarousel.defaultProps = {
  sortParams: '-featured,-price,-ordination',
  maxItems: 16,
  showUnitTag: false,
  cardLink: '/seminovos',
};

PortalUsedVehiclesCarousel.propTypes = {
  sortParams: PropTypes.string,
  maxItems: PropTypes.number,
  showUnitTag: PropTypes.bool,
  cardLink: PropTypes.string,
};
