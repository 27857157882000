import { Component } from 'preact';
import PropTypes from 'prop-types';
import SubscriptionUsedModelForm from '../SubscriptionUsedModelForm';

class SubscriptionUsedModelFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      simulationWorked: false,
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
      baseParams: {},
    };

    this.sendFirstConversion = this.sendFirstConversion.bind(this);
    this.sendSecondConversion = this.sendSecondConversion.bind(this);
  }

  sendFirstConversion(params, callback) {
    this.setState({ isSubmittingForm: true }, () => {
      return serviceConversion
        .convert(params)
        .then(() => {
          this.setState(
            {
              isSubmittingForm: false,
              simulationWorked: true,
            },
            () => {
              callback();
            },
          );
        })
        .catch(error => {
          console.error(error);
        });
    });
  }

  sendSecondConversion(params, callback) {
    this.setState({ isSubmittingForm: true }, () => {
      let status = 'error',
        data = null;

      return serviceConversion
        .convert(params)
        .then(() => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: false,
          });
          status = 'success';
          data = params;

          callback();
        })
        .catch(error => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: true,
          });
          data = error;
          console.error(error);
        })
        .then(() => {
          document.dispatchEvent(
            new CustomEvent('new_conversion', {
              detail: {
                status: status,
                data: data,
              },
            }),
          );
          this.setState({
            showOverlay: true,
          });
        });
    });
  }

  render() {
    const { simulationWorked, isSubmittingForm } = this.state;

    const {
      brand,
      product,
      model,
      modelYear,
      version,
      linkPrivacyPolicy,
      contractDurations,
      franchises,
      jobCategories,
      vehiclePrice,
      isZeroKm,
      unit,
      shouldShowFullConversionData,
    } = this.props;

    return (
      <SubscriptionUsedModelForm
        simulationWorked={simulationWorked}
        isSubmittingForm={isSubmittingForm}
        linkPrivacyPolicy={linkPrivacyPolicy}
        sendFirstConversion={this.sendFirstConversion}
        sendSecondConversion={this.sendSecondConversion}
        contractDurations={contractDurations}
        franchises={franchises}
        jobCategories={jobCategories}
        brand={brand}
        product={product}
        model={model}
        modelYear={modelYear}
        version={version}
        vehiclePrice={vehiclePrice}
        isZeroKm={isZeroKm}
        unit={unit}
        shouldShowFullConversionData={shouldShowFullConversionData}
      />
    );
  }
}

SubscriptionUsedModelFormApp.defaultProps = {
  brand: '',
  product: '',
  model: '',
  version: '',
  linkPrivacyPolicy: '',
  unit: '',
  contractDurations: [],
  franchises: [],
  jobCategories: [],
  vehiclePrice: '',
  isZeroKm: false,
};

SubscriptionUsedModelFormApp.propTypes = {
  brand: PropTypes.string,
  product: PropTypes.string,
  model: PropTypes.string,
  modelYear: PropTypes.string,
  version: PropTypes.string,
  vehiclePrice: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  isZeroKm: PropTypes.bool,
  shouldShowFullConversionData: PropTypes.bool,
  unit: PropTypes.string,
  contractDurations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  franchises: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  jobCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default SubscriptionUsedModelFormApp;
