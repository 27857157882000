import { Component } from 'preact';
import PropTypes from 'prop-types';
import { phoneMasks, priceMask, dateMasks } from '%/utils/masks';
import IMask from 'imask';
import handleInputChange from '%/utils/handleInputChange';
import validationFactory from '%/utils/validation';
import CustomSelect from '../CustomSelect';
import CustomCheck from '../CustomCheck';
import SubmitButton from '../SubmitButton';
import { getSubscriptionPrice } from '../../utils/getSubscriptionPrice';

class SubscriptionUsedModelForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.handleInputChange = handleInputChange.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.maskFactory = this.maskFactory.bind(this);
    this.setFieldMasks = this.setFieldMasks.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.getFirstConversionParams = this.getFirstConversionParams.bind(this);
    this.getSecondConversionParams = this.getSecondConversionParams.bind(this);
    this.getUrl = this.getUrl.bind(this);
  }

  componentDidMount() {
    this.validator = validationFactory(
      `.subscription-used-model-form .d-block`,
    );

    this.setFieldMasks();
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  // eslint-disable-next-line consistent-return
  handleValidation() {
    const { step } = this.state;
    const { sendFirstConversion, sendSecondConversion } = this.props;
    const fields = document.querySelector(
      '.subscription-used-model-form .d-block',
    );

    const invalidInputs = this.validator.validateAll(fields);
    const formIsValid = invalidInputs.length === 0;

    if (!formIsValid) {
      return formIsValid;
    }

    if (step === 1) {
      const params = this.getFirstConversionParams();
      this.setSubscriptionPrice();
      sendFirstConversion(params, () => {
        this.handleStepChange();
      });
    } else if (step === 3) {
      const params = this.getSecondConversionParams();
      sendSecondConversion(params, () => {
        this.handleStepChange();
      });
    } else if (step === 4) {
      this.setState({
        descriptionForm: 'Simulação concluída',
      });
    }
  }

  handleStepChange(isStepIncrease = true) {
    const lastStep = 4;
    const { step } = this.state;

    if (isStepIncrease && step <= lastStep) {
      this.setState({
        step: step + 1,
      });
    } else {
      this.setState({
        step: step - 1,
      });
    }
  }

  async setSubscriptionPrice() {
    const { vehiclePrice, isZeroKm } = this.props;
    const { franchise, dateOfBirth, contractDuration, contractRate } =
      this.state;

    if (!vehiclePrice) return;

    const formattedVehiclePrice = this.formatMoneyToFloat(vehiclePrice);
    const formattedContractRate = this.formatMoneyToFloat(contractRate);

    const formattedDateOfBith = dateOfBirth.split('/').reverse().join('-');

    const contractRatePerCent =
      (formattedContractRate / formattedVehiclePrice) * 100;

    const usedVehicleIdentifier = 530;
    const zeroKmIdentifier = 1;
    const carParameter = isZeroKm ? zeroKmIdentifier : usedVehicleIdentifier;

    const requestBody = {
      kmAllowenceMonth: parseInt(franchise, 10),
      leaseTerm: parseInt(contractDuration, 10),
      contractTax: contractRatePerCent,
      birthDate: formattedDateOfBith,
      parameterId: carParameter,
      contractRulesId: 1,
      value: formattedVehiclePrice,
    };

    const { subscriptionPrice, acquisitionPrice } = await getSubscriptionPrice(
      requestBody,
    );

    this.setState({
      subscriptionPrice,
      acquisitionPrice,
      isFetchingPrice: false,
    });
  }

  getFirstConversionParams() {
    const {
      name,
      email,
      phone,
      dateOfBirth,
      contractDuration,
      contractRate,
      franchise,
    } = this.state;

    const {
      brand,
      product,
      model,
      modelYear,
      vehiclePrice,
      version,
      unit,
      shouldShowFullConversionData,
    } = this.props;

    return {
      category: 'used_model',
      bait: 'ASSINATURA - SEMINOVO',
      brand,
      product,
      model,
      modelYear,
      vehiclePrice,
      version,
      name,
      email,
      phone,
      dateOfBirth,
      contractDuration,
      contractRate,
      franchise,
      unit,
      shouldShowFullConversionData,
    };
  }

  getSecondConversionParams() {
    const {
      name,
      email,
      phone,
      dateOfBirth,
      contractDuration,
      contractRate,
      franchise,
      cnh,
      cnhValidity,
      address,
      houseNumber,
      cep,
      jobCategory,
      cpfCnpj,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      subscriptionPrice,
      acquisitionPrice,
    } = this.state;

    const {
      brand,
      product,
      model,
      modelYear,
      vehiclePrice,
      version,
      shouldShowFullConversionData,
    } = this.props;

    return {
      category: 'used_model',
      bait: 'ASSINATURA - SEMINOVO',
      brand,
      product,
      model,
      modelYear,
      vehiclePrice,
      version,
      name,
      email,
      phone,
      dateOfBirth,
      contractDuration,
      contractRate,
      franchise,
      cnh,
      cnhValidity,
      address,
      houseNumber,
      cep,
      jobCategory,
      cpfCnpj,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      shouldShowFullConversionData,
      subscriptionPrice,
      acquisitionPrice,
    };
  }

  setFieldMasks() {
    const fields = [
      {
        field: this.phoneInput,
        state: 'phone',
        masks: phoneMasks,
      },
      {
        field: this.cepInput,
        state: 'cep',
        masks: [{ mask: '00000-000' }],
      },
      {
        field: this.cnhInput,
        state: 'cnh',
        masks: [{ mask: '00000000000' }],
      },
      {
        field: this.dateOfBirthInput,
        state: 'dateOfBirth',
        masks: dateMasks,
      },
      {
        field: this.cnhValidityInput,
        state: 'cnhValidity',
        masks: dateMasks,
      },
      {
        field: this.contractRateInput,
        state: 'contractRate',
        masks: [priceMask],
      },
      {
        field: this.cpfCnpjInput,
        state: 'cpfCnpj',
        masks: [
          {
            mask: '000.000.000-00',
            min: 0,
            max: 14,
          },
          {
            mask: '00.000.000/0000-00',
            min: 15,
            max: 18,
          },
        ],
      },
    ];

    fields.forEach(field => {
      this.maskFactory(field);
    });
  }

  getUrl(slug) {
    const pathNameParts = window.location.pathname.split('/');
    const channelSlug = pathNameParts[1] !== `${slug}` ? pathNameParts[1] : '';
    const newPathname = channelSlug ? `${channelSlug}/${slug}/` : `${slug}/`;

    return `${window.location.origin}/${newPathname}`;
  }

  formatMoneyToFloat(value) {
    return parseFloat(
      value.replace('R$ ', '').replace('.', '').replace(',', '.'),
    );
  }

  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  maskFactory({ field, state, masks }) {
    if (!field) {
      return;
    }

    const fieldMask = IMask(field, {
      mask: [...masks],
    });

    fieldMask.on('accept', () => this.setState({ [state]: fieldMask.value }));
  }

  resetState() {
    this.setState({
      step: 1,
      name: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      contractDuration: '',
      contractRate: '',
      franchise: '',
      cnh: '',
      cnhValidity: '',
      address: '',
      houseNumber: '',
      cep: '',
      jobCategory: '',
      cpfCnpj: '',
      subscriptionPrice: 'N/D',
      acquisitionPrice: 'N/D',
      phoning: false,
      mailing: false,
      whatsapping: false,
      isFetchingPrice: false,
      contactOptions: 'on',
      titleForm: 'Simular assinatura',
      descriptionForm:
        'Preencha todas as etapas do formulário abaixo para simular o valor da sua assinatura.',
    });
  }

  render() {
    const {
      step,
      name,
      email,
      phone,
      dateOfBirth,
      contractDuration,
      contractRate,
      franchise,
      cnh,
      cnhValidity,
      address,
      houseNumber,
      cep,
      jobCategory,
      cpfCnpj,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      titleForm,
      descriptionForm,
      subscriptionPrice,
      acquisitionPrice,
      isFetchingPrice,
    } = this.state;

    const {
      simulationWorked,
      isSubmittingForm,
      linkPrivacyPolicy,
      contractDurations,
      franchises,
      jobCategories,
    } = this.props;

    return (
      <form
        className="conversion-form subscription-used-model-form"
        id="subscription-used-model-form"
        onSubmit={e => e.preventDefault()}
        ref={form => {
          // eslint-disable-next-line react/no-unused-class-component-methods
          this.form = form;
        }}
        noValidate
      >
        <header>
          <div className="conversion-form__header-phrase">
            <h2>{titleForm}</h2>
            <p>{descriptionForm}</p>
          </div>
        </header>

        <div className="form-conversion__body">
          <div
            className={`form-conversion-first-step ${
              step === 1 ? 'd-block' : 'd-none'
            }`}
          >
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={name}
                type="text"
                className="form-control"
                name="name"
                required
                placeholder="Nome"
                maxLength={80}
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={email}
                type="email"
                className="form-control"
                name="email"
                required
                placeholder="E-mail"
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={phone}
                type="text"
                className="form-control"
                name="phone"
                required
                placeholder="Telefone"
                data-bouncer-target="#invalid-phone"
                ref={phoneInput => {
                  this.phoneInput = phoneInput;
                }}
              />
              <div id="invalid-phone" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={dateOfBirth}
                type="text"
                className="form-control"
                name="dateOfBirth"
                required
                placeholder="Data de nascimento"
                data-bouncer-target="#invalid-date-of-birth"
                ref={dateOfBirthInput => {
                  this.dateOfBirthInput = dateOfBirthInput;
                }}
              />
              <div id="invalid-date-of-birth" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="contractDuration"
                options={contractDurations}
                value={contractDuration}
                placeholderValue="Prazo do contrato"
                searchEnabled={false}
                required
                errorMessageDisplay="#invalid-contract-duration"
              />

              <div
                id="invalid-contract-duration"
                className="invalid-feedback is-invalid-contract-duration"
              />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={contractRate}
                type="text"
                className="form-control"
                name="contractRate"
                required
                placeholder="Taxa de contrato (entrada)"
                data-bouncer-target="#invalid-contract-rate"
                ref={contractRateInput => {
                  this.contractRateInput = contractRateInput;
                }}
              />
              <div id="invalid-contract-rate" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="franchise"
                options={franchises}
                value={franchise}
                placeholderValue="Franquia KM"
                searchEnabled={false}
                required
                errorMessageDisplay="#invalid-franchises"
              />

              <div
                id="invalid-franchises"
                className="invalid-feedback is-invalid-franchises"
              />
            </div>

            <hr />

            <SubmitButton
              classes="btn button button--large button--primary w-100 mb-2"
              label="SIMULAR ASSINATURA"
              isSubmitting={isSubmittingForm}
              handleClick={this.handleValidation}
            />
          </div>

          <div
            className={`success-simulation ${
              step === 2 && simulationWorked ? 'd-block' : 'd-none'
            }`}
          >
            <h2>Resultado da simulação</h2>

            <hr />

            <div className="success-simulation__content">
              <i className="icon icon-check-mark" />

              <div className="success-simulation__content-info contract-duration">
                <h6>Prazo do contrato:</h6>
                <h6>
                  <span>{contractDuration} meses</span>
                </h6>
              </div>

              <div className="success-simulation__content-info franchise">
                <h6>Franquia de KM:</h6>
                <h6>
                  <span>{franchise} KM/mês</span>
                </h6>
              </div>

              <div className="success-simulation__content-info subscription-price">
                <h5>Valor da assinatura:</h5>
                <h5>
                  <span>
                    {isFetchingPrice ? 'Calculando...' : subscriptionPrice}
                  </span>
                </h5>
              </div>

              <div className="success-simulation__content-info acquisition-value">
                <h5>Valor de aquisição:</h5>
                <h5>
                  <span>
                    {isFetchingPrice ? 'Calculando...' : acquisitionPrice}
                  </span>
                </h5>
              </div>
            </div>

            <hr />

            <div>
              <p>Envie dados complementares para receber uma proposta</p>
            </div>

            <hr />

            <button
              type="button"
              className="btn button button--large button--primary w-100 mb-2"
              onClick={this.handleStepChange}
            >
              Próximo
            </button>
          </div>

          <div
            className={`error-simulation ${
              step === 2 && !simulationWorked ? 'd-block' : 'd-none'
            }`}
          >
            <h2>Resultado da simulação</h2>
            <hr />

            <div className="error-simulation__content">
              <i className="icon icon-error-mark" />

              <h5>
                Esse veículo não está disponível para essas condições de
                assinatura.
              </h5>
            </div>

            <hr />

            <a
              href={this.getUrl('seminovos')}
              type="button"
              className="btn button button--large button--primary--outline w-100 mb-2"
            >
              Escolher outro veículo
            </a>

            <a
              href="tel:08003390922"
              className="btn button button--large button--primary w-100 mb-0"
            >
              <span className="pr-2">Fale conosco</span>
              <i className="icon icon-phone" />
            </a>
          </div>

          <div
            className={`form-conversion-second-step ${
              step === 3 ? 'd-block' : 'd-none'
            }`}
          >
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={cnh}
                type="text"
                className="form-control"
                name="cnh"
                required
                placeholder="CNH"
                data-bouncer-target="#invalid-cnh"
                ref={cnhInput => {
                  this.cnhInput = cnhInput;
                }}
              />
              <div id="invalid-cnh" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={cnhValidity}
                type="text"
                className="form-control"
                name="cnhValidity"
                required
                placeholder="Validade CNH"
                data-bouncer-target="#invalid-cnh-validaty"
                ref={cnhValidityInput => {
                  this.cnhValidityInput = cnhValidityInput;
                }}
              />
              <div id="invalid-cnh-validaty" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={address}
                type="text"
                className="form-control"
                name="address"
                required
                placeholder="Endereço"
                maxLength={80}
                data-bouncer-target="#invalid-address"
              />
              <div id="invalid-address" className="invalid-feedback" />
            </div>

            <div className="row">
              <div className="col-lg-4 pr-1">
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    value={houseNumber}
                    type="text"
                    className="form-control"
                    name="houseNumber"
                    placeholder="Número"
                    data-bouncer-target="#invalid-house-number"
                    required
                    ref={houseNumberInput => {
                      // eslint-disable-next-line react/no-unused-class-component-methods
                      this.houseNumberInput = houseNumberInput;
                    }}
                  />
                  <div id="invalid-house-number" className="invalid-feedback" />
                </div>
              </div>
              <div className="col-lg-8 pl-0">
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    value={cep}
                    type="text"
                    className="form-control"
                    name="cep"
                    required
                    placeholder="CEP"
                    data-bouncer-target="#invalid-cep"
                    ref={cepInput => {
                      this.cepInput = cepInput;
                    }}
                  />
                  <div id="invalid-cep" className="invalid-feedback" />
                </div>
              </div>
            </div>

            <div className="form-group">
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="jobCategory"
                options={jobCategories}
                value={jobCategory}
                placeholderValue="Categoria de trabalho"
                searchEnabled={false}
                required
                errorMessageDisplay="#invalid-job-category"
                ref={jobCategoryInput => {
                  // eslint-disable-next-line react/no-unused-class-component-methods
                  this.jobCategoryInput = jobCategoryInput;
                }}
              />

              <div
                id="invalid-job-category"
                className="invalid-feedback is-invalid-job-category"
              />
            </div>

            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={cpfCnpj}
                type="text"
                className="form-control"
                name="cpfCnpj"
                required
                placeholder="CPF ou CNPJ"
                data-bouncer-target="#invalid-cpf-cnpj"
                ref={cpfCnpjInput => {
                  this.cpfCnpjInput = cpfCnpjInput;
                }}
              />
              <div id="invalid-cpf-cnpj" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                defaultValue={subscriptionPrice}
                type="hidden"
                className="form-control"
                name="subscriptionPrice"
                required
              />
            </div>
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                defaultValue={acquisitionPrice}
                type="hidden"
                className="form-control"
                name="acquisitionPrice"
                required
              />
            </div>

            <fieldset>
              <legend className="conversion-form__control-label">
                Quero receber contato por:
              </legend>

              <div className="form-check form-check-inline">
                <CustomCheck
                  type="checkbox"
                  name="mailing"
                  value="true"
                  isChecked={mailing}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  E-mail
                </CustomCheck>
              </div>

              <div className="form-check form-check-inline">
                <CustomCheck
                  type="checkbox"
                  name="whatsapping"
                  value="true"
                  isChecked={whatsapping}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Whatsapp
                </CustomCheck>
              </div>

              <div className="form-check form-check-inline">
                <CustomCheck
                  type="checkbox"
                  name="phoning"
                  value="true"
                  isChecked={phoning}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Telefone
                </CustomCheck>
              </div>

              <div className="form-group contact-options">
                <input
                  type="text"
                  className="form-control d-none"
                  name="contact-options"
                  required
                  placeholder="Opções de contato"
                  data-bouncer-target="#invalid-contact-options"
                  value={contactOptions}
                  checked={contactOptions}
                />
                <div
                  id="invalid-contact-options"
                  className="invalid-feedback"
                />
              </div>
            </fieldset>

            <footer className="form-conversion__footer mt-3">
              Ao informar meus dados, eu concordo com a{' '}
              <a href={linkPrivacyPolicy}>Política de privacidade</a>.
            </footer>

            <hr />

            <SubmitButton
              classes="btn button button--large button--primary w-100 mb-2"
              label="ENVIAR"
              isSubmitting={isSubmittingForm}
              handleClick={this.handleValidation}
            />

            <button
              type="button"
              className="btn button button--large button--primary--outline w-100 mb-0"
              onClick={() => {
                this.handleStepChange(false);
              }}
            >
              FECHAR
            </button>
          </div>

          <div
            className={`success-simulation ${
              step === 4 ? 'd-block' : 'd-none'
            }`}
          >
            <h2>Dados enviados com sucesso</h2>

            <hr />

            <div className="success-simulation__content success-simulation-done">
              <i className="icon icon-check-mark" />

              <hr />

              <p>Um de nossos representantes entrará em contato com você.</p>
            </div>

            <button
              type="button"
              className="btn button button--large button--primary--outline w-100 mb-2"
              onClick={() => {
                this.resetState();
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      </form>
    );
  }
}

SubscriptionUsedModelForm.defaultProps = {
  brand: '',
  product: '',
  model: '',
  modelYear: '',
  version: '',
  vehiclePrice: '',
  unit: '',
  simulationWorked: false,
  isSubmittingForm: false,
  isZeroKm: false,
  shouldShowFullConversionData: false,
  linkPrivacyPolicy: '',
  contractDurations: [],
  franchises: [],
  jobCategories: [],
  sendFirstConversion: () => {},
  sendSecondConversion: () => {},
};

SubscriptionUsedModelForm.propTypes = {
  brand: PropTypes.string,
  product: PropTypes.string,
  model: PropTypes.string,
  modelYear: PropTypes.string,
  version: PropTypes.string,
  vehiclePrice: PropTypes.string,
  simulationWorked: PropTypes.bool,
  isSubmittingForm: PropTypes.bool,
  linkPrivacyPolicy: PropTypes.string,
  isZeroKm: PropTypes.bool,
  shouldShowFullConversionData: PropTypes.bool,
  contractDurations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  franchises: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  jobCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  unit: PropTypes.string,
  sendFirstConversion: PropTypes.func,
  sendSecondConversion: PropTypes.func,
};

export default SubscriptionUsedModelForm;
