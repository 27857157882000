const formatPriceToBrMoney = price =>
  price.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

export const getSubscriptionPrice = async requestBody => {
  const baseUrl = document.querySelector("meta[name='auto_api_url']").content;
  const path = '/carflip/rental-price';

  const fullUrl = `${baseUrl}${path}`;

  const response = await fetch(fullUrl, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (!data) return {};

  const subscriptionPrice = formatPriceToBrMoney(data.rentValue);
  const acquisitionPrice = formatPriceToBrMoney(data.buyValue);

  const planPrices = {
    subscriptionPrice,
    acquisitionPrice,
  };

  return planPrices;
};
