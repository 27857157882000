import '../auto/global';
import './components';
import './store';
import '../auto/stores';
// Importando o alias para a pasta de componentes.
import '#';
import Carousel from '../auto/carousel';
import Choices from '../auto/choices';
import MicroModal from '../auto/micromodal';
import serviceFactory from '../auto/autoForceServiceFactory';
import serviceConversionFactory from '../auto/autoBoxOfficeServiceFactory';
import railsDateToChronometer from '../auto/utils/railsDateToChronometer';
import { pushDataToDataLayer } from '../auto/utils/pushDataToDataLayer';
import SubscriptionUsedModelFormApp from '../auto/components/SubscriptionUsedModelFormApp';
import { autoServiceApiV2 } from '../auto/services/autoServiceApiV2';

// Criando um service que será armazenado como Singleton.
window.service = serviceFactory();
window.serviceConversion = serviceConversionFactory();

window.Carousel = Carousel;
window.Choices = Choices;
window.MicroModal = MicroModal;

window.railsDateToChronometer = railsDateToChronometer;
window.pushDataToDataLayer = pushDataToDataLayer;
window.SubscriptionUsedModelFormApp = SubscriptionUsedModelFormApp;

window.services = {
  autoServiceApiV2,
};

if (process.env.NODE_ENV === 'development') {
  // Necessário para fazer o Preact ficar disponível no React Dev Tools
  // eslint-disable-next-line global-require
  require('preact/debug');

  // Adicionando o app do guide apenas em desenvolvimento
  $(document).ready(() => {
    // require('./guide-app.js');
  });
}

$(document).ready(() => {
  // create Swiper object
  // eslint-disable-next-line no-new
  new window.Carousel('.slide', {
    autoplay: {
      delay: 5000,
    },
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      init: () => {
        window.videoBG.adjustsHeight('.slide');
      },
    },
  });

  const slideThumbs = new window.Carousel('.swiper-thumbs-container', {
    direction: 'vertical',
    slidesPerView: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  // eslint-disable-next-line no-new
  new window.Carousel('.swiper-images-container', {
    direction: 'horizontal',
    navigation: {
      nextEl: '.slide-images__control--next',
      prevEl: '.slide-images__control--prev',
    },
    thumbs: {
      swiper: slideThumbs,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      767: {
        direction: 'vertical',
      },
    },
  });
});
