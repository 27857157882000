import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeItemOfferDisplay } from '../../../utils/makeItemOfferDisplay';

export default class PortalUsedVehiclesCard extends Component {
  constructor(props) {
    super(props);

    this.itemOfferDisplay = makeItemOfferDisplay(
      this.props.oldPrice,
      this.props.price,
    );

    this.isPremiumVehicle = this.isPremiumVehicle.bind(this);
    this.technicalItems = this.technicalItems.bind(this);
  }

  isPremiumVehicle(price) {
    const { channelPremiumPrice } = this.props;
    let premiumPrice = 150000;

    if (channelPremiumPrice > 0) {
      premiumPrice = channelPremiumPrice;
    }

    return price >= premiumPrice;
  }

  technicalItems() {
    const { km, exchange, fuelText } = this.props;
    const items = [
      {
        label: 'Câmbio',
        value: exchange,
      },
      {
        label: 'Quilometragem',
        value: km && `${km} KM`,
      },
      {
        label: 'Combustível',
        value: fuelText,
      },
    ];

    return items;
  }

  render() {
    const {
      moduleLink,
      slug,
      itemImage,
      kind,
      name,
      itemUnit,
      showUnitTag,
      fabricationYear,
      modelYear,
      shielded,
      price,
      subscriptionPrice,
    } = this.props;

    return (
      <div className="card">
        <div className="card__header">
          {this.isPremiumVehicle(price) && (
            <div className="card__badges">
              <div className="card__badge-item badge badge--premium">
                <i className="icon icon-diamond" />
                Premium
              </div>
            </div>
          )}
          <a href={`${moduleLink}/${slug}`}>
            <div className="card__image card__image--extra-large">
              {itemImage.middle_image ? (
                <div
                  className="card__image-value"
                  style={`background-image: url('${itemImage.middle_image}')`}
                />
              ) : (
                <div
                  className={classNames('card__image-value', {
                    'default-bg__motorcycle_middle-default': kind === 'Moto',
                    'default-bg__model-middle-default': kind !== 'Moto',
                  })}
                />
              )}
            </div>
          </a>
        </div>

        <div className="card__content">
          {showUnitTag && (
            <div
              className="card__unit text-left"
              data-match-height="card-used-model-unit"
            >
              <i className="icon icon-map" />
              <span> {itemUnit.name} </span>
            </div>
          )}
          <div
            className="card__title text-left"
            data-match-height="card-used-model-title"
          >
            {name}
          </div>
          <div>
            <span className="badge badge-primary mr-2">{`${fabricationYear} / ${modelYear}`}</span>
            {shielded && <span className="badge badge--dark">Blindado</span>}
          </div>
          <hr className="card__separator" />
          <ul
            className="card__list list list--primary"
            data-match-height="card-used-model-items-list"
          >
            {this.technicalItems().map(
              item =>
                !!item.value && (
                  <li>
                    <b>{item.label}: </b>
                    {item.value}
                  </li>
                ),
            )}
          </ul>
          <hr className="card__separator" />
        </div>
        <div className="card__footer">
          <div
            data-match-height="card-used-model-triggers"
            className="card__triggers"
            data-vehicle-subscription-price={subscriptionPrice}
          >
            {this.itemOfferDisplay.secondary_call && (
              <div className="card__trigger">
                <s>{this.itemOfferDisplay.secondary_call}</s>
              </div>
            )}
            {this.itemOfferDisplay.main_call && (
              <div className="card__title">
                {this.itemOfferDisplay.main_call}
              </div>
            )}
            {this.itemOfferDisplay.featured && (
              <div className="card__trigger-value">
                {this.itemOfferDisplay.featured}
              </div>
            )}
            <a
              href={`${moduleLink}/${slug}`}
              className="btn button button--block button--large card__cta button--primary"
            >
              Estou interessado
            </a>
          </div>
        </div>
      </div>
    );
  }
}

PortalUsedVehiclesCard.defaultProps = {
  itemImage: {},
  slug: null,
  kind: null,
  name: null,
  exchange: '',
  km: '',
  fuelText: '',
  subscriptionPrice: null,
  price: null,
  oldPrice: null,
  itemUnit: null,
  showUnitTag: null,
  fabricationYear: null,
  modelYear: null,
  shielded: null,
  channelPremiumPrice: null,
};

PortalUsedVehiclesCard.propTypes = {
  moduleLink: PropTypes.string.isRequired,
  slug: PropTypes.string,
  itemImage: PropTypes.objectOf,
  kind: PropTypes.string,
  name: PropTypes.string,
  exchange: PropTypes.string,
  km: PropTypes.string,
  fuelText: PropTypes.string,
  itemUnit: PropTypes.string,
  showUnitTag: PropTypes.bool,
  fabricationYear: PropTypes.string,
  modelYear: PropTypes.string,
  subscriptionPrice: PropTypes.string,
  price: PropTypes.string,
  oldPrice: PropTypes.string,
  shielded: PropTypes.bool,
  channelPremiumPrice: PropTypes.number,
};
